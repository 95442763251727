<template>
  <v-app id="inspire">
       <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-content>
      <v-container fluid>
       
    <!-- <v-container fluid> -->
    Please Wait , <br>
    Redirecting you to Library Portal 
     <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay>
     
     
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import epsSidebar from "../shared/Sidebar";
import epsHeader from "../shared/Header";
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
     search: "",
     snackbar: "",
     snackbar_msg: "",
      color: "",
     valid: false,
   
    usertype:null,
    overlay:false,
    
   
    color_array: [
      "#3CDBBB",
      "#FFBD5D",
      "#5EA9FF",
      "#F98B50",
      "#DAF950",
      "#86F950",
      "#50D3F9",
      "#C850F9",
      "#C850F9",
    ],
  }),
  components: {
    epsSidebar: epsSidebar,
    epsHeader: epsHeader,
  },
  mounted() {
   // alert("in tpo")
        console.log(this.$route) // outputs 'yay'
      console.log(this.$route.erp_url) // outputs 'yay'
      console.log(this.$route.query) // outputs 'yay'
     this.overlay = true;
     const formdata= {
        uid:this.$route.query.uid,
        hash:this.$route.query.hash_key,
        url:this.$route.query.erp_url,
     }
   
         axios
         .post("/login/erp_check",formdata)
      .then((res) => {
        console.log(" hihiiiiiiiiiiiiiiiiiii");
        console.log(res.data);
        if (res.data.status == "200") {
        //   
         
          console.log(res);
              var params = {
              uid: res.data.username,
              pass: res.data.password,
            };
            axios.post("/login/process", params).then((res1) => {
              window.console.log("res" + res1.data.policy_read);
              //alert(res.data.msg)
              // if (res1.data.msg == "200") {
              //   localStorage.setItem("awspath", res1.data.awspath);
              //   localStorage.setItem("EPS-token", res1.data.token);
              //   localStorage.setItem("EPS-uid", res1.data.uid);
              // // localStorage.setItem("Isbypass", true);
              //   localStorage.setItem("instuid",  res.data.uid);
              //   if (res1.data.policy_read == true) this.$router.push("/home");
              //   else {
              //     this.dialog = true;
              //   }
              // }
              if (res1.data.msg == "200") {
                          localStorage.setItem("user_id", res.data.user_id)
                          localStorage.setItem("awspath", res1.data.awspath)
                          localStorage.setItem("EPS-token", res1.data.token);
                          // localStorage.setItem("EPS-uid", crypto.encrypt( res.data.uid));
                          localStorage.setItem("EPS-uid", res1.data.uid);
                          localStorage.setItem("logo_url", res1.data.logo_url);
                          localStorage.setItem("org_name", res1.data.org_name);
                          localStorage.setItem("org_trust_name", res1.data.org_trust_name);
                          localStorage.setItem("org_address", res1.data.org_address);
                          if (res.data.policy_read == true) {
                              if (res.data.firstlogin == "1") {
                                  localStorage.setItem("first-login", res1.data.firstlogin);
                                  this.$router.push("/update-password");
                              } else {
                                  localStorage.setItem("first-login", "0");
                                 
                                  this.$router.push("/home");
                                  return
                              }
                          } else {
                              this.dialog = true
                          }
                          //window.location="/home"
                          
                      this.$router.push({ name: "home" }).catch(()=>{});
                      }
            });
          
        } else if(res.data.status == "NOTFOUND") {
          this.$router.push("/");
          this.showSnackbar("#b71c1c","Learner Login Not Found");
        } else if(res.data.msg == "KEY_NA") {
          console.log(" ec key not found at Library ")
          this.$router.push("/");
          this.showSnackbar("#b71c1c","Contact to Librarian");
        }else{
          this.$router.push("/");
            this.showSnackbar("#b71c1c","Something Went Wrong");
        }
      })

      
  },
   methods: {
   
 showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },


  },
};
</script>

<style scoped>
.center {
  margin: auto;
  width: 50%;
  padding: 30px 0;
  text-align: center;
}
.center2 {
  margin: auto;
  width: 50%;
  padding: 40px 0;
  text-align: left;
}
.question{
    margin:8px; height:100%;padding-top:15px;padding-left:15px;padding-right:15px;
}
</style>